import { listUser } from "@/apis/user";
export function getUser() {
  //获取区域信息
  interface IListUser {
    MaxCount: number,
    SkipCount: number
  }
  return new Promise((resolve) => {
    listUser({
      MaxCount: 100,
      SkipCount: 0
    }).then((res: any) => {
      if (res.succeeded) {
        resolve(res.data.items)
      }
    });
  })
}
