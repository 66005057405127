export interface IParams {
  name: string,
  userId: number | string,
  recordTime: string,
  noticeSheetId?: string,
  chargingVoucher?: string,
  price: number | string,
  paid: boolean,
  remark?: string,
  areaId: number | string,
}

export const rule = {
  name: {
    type: ["String"],
    name: "户主信息",
    required: true,
  },
  userId: {
    type: ["String", "Number"],
    name: "抄表员",
    required: true,
  },
  recordTime: {
    type: ["String"],
    name: "抄表员现场登记时间",
    required: false,
  },
  noticeSheetId: {
    type: ["String"],
    name: "通知单编号",
    required: false,
  },
  chargingVoucher: {
    type: ["String"],
    name: "收费凭证编号",
    required: false,
  },
  paid: {
    type: ["Boolean"],
    name: "是否支付",
    required: true,
  },
  price: {
    type: ["String", "Number"],
    name: "应收价格",
    required: true,
  },
  remark: {
    type: ["String"],
    name: "备注",
    required: false,
  },
  areaId: {
    type: ["String", "Number"],
    name: "所在片区",
    required: true,
  },
};